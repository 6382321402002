body{
    overflow-x: hidden;
}

.react-modal-overlay{
    background: rgba(0,0,0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.link{
    color: black;
    text-decoration: none;
}