.quadrado-iniciar-treino p{
    margin: auto;
}
.quadrado-iniciar-treino button{
    display: flex;
    margin: auto;
    align-self: flex-end;
}

.div-home-academia-button-iniciar-treino{
    display: flex;
    justify-content: end;
    margin-right: 30px;
}   

.button-iniciar-treino{
    display: flex;
    margin-top: 20px;
    border-radius: 5px;
    background-color: #F2F4F7;
}
.button-iniciar-treino p{
    margin: auto;
}

.div-treino-ativo{
    margin-top: 50px;
}

.div-treino-separado{
    background-color: #6996FA;
    border-radius: 10px;
    padding: 10px;
    margin: 15px;
    margin-right: 30px;
    align-items: center;
    align-self: center;
    /* width: 90%; */
}
.div-treino-separado button{
    display: flex;
    align-self: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 20px;
    padding-right: 20px;
}

.div-treino-concluido{
    margin: 15px;
}

.button-treino-abrir-exercicio{
    margin: auto;
    border-radius: 5px;
    background-color: #F2F4F7;
}
.button-treino-abrir-exercicio p{
    margin: auto;

}