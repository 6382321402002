.button-cadastro-adicionar-exerc{
    margin-top: 100px;
}



.div-cadastro-exerc-feitos-series{
    background-color: #6996FA;
    border-radius: 10px;
    padding: 10px;
    margin: 15px;
    margin-right: 30px;
    align-items: center;
    align-self: center;
}

.p-div-cadastro-exerc-feitos-series{
    margin: 15px;
    color: white;
}